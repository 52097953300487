<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <ul class="nav nav-tabs customtab" role="tablist">
            <li class="nav-item"> <a class="nav-link active" id="main-nav" data-toggle="tab" href="#main" role="tab" @click="changeTab('main')"><span
                  class="hidden-sm-up"></span> <span class="hidden-xs-down">Home Page</span></a> </li>
            <li class="nav-item"> <a class="nav-link" id="seo-nav" data-toggle="tab" href="#seo" role="tab" @click="changeTab('SEO')"><span
                  class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="main" role="tabpanel">
              <div class="card">
                <div class="card-header">
                  <h5 class="card-title">Slide Show</h5>
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="form-group mb-0 mr-2">
                      <div class="custom-control custom-control-inline custom-checkbox">
                        <input 
                          type="checkbox" name="autoplaySlideshow" 
                          id="autoplaySlideshow" class="custom-control-input" @change="updateAutoplay('status', $event)"
                          :checked="autoPlay.sw_title === 'Y'"
                        >
                        <label for="autoplaySlideshow" class="custom-control-label">Enable Autoplay?</label>
                      </div>
                    </div>
                    <div class="form-group row mb-0 align-items-center">
                      <label for="autoplayDuration" class="col-auto col-form-label">Autoplay Delay</label>
                      <div class="col-md-4">
                        <div class="input-group input-group-sm">
                          <input @keyup="changeNum" type="text" maxlength="3" :disabled="autoPlay.sw_title === 'N'" v-model="autoPlay.sw_description" class="form-control form-control-sm" @change="updateAutoplay('delay', $event)">
                          <div class="input-group-append"><span class="input-group-text">S</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div v-if="data.data">
                    <draggable class="row gutter-20" v-model="data.data" v-bind="dragOptions" @start="startDrag" @end="endDrag">
                      <div class="col-md-3" v-for="(v,k) in data.data" :key="k" style="cursor: move;">
                        <div class="wrap_slider_img" v-tooltip="v.as_update_date ? 'Last updated : ' + v.as_update_date.dates('format2') : 'Created : ' + v.as_create_date.dates('format2')">
                          <img :src="uploader((v.as_image || '').removeWebp(),'310')" class="img-responsive" />
                          <div class="slider_name">
                            <p v-tooltip="v.as_title">{{ splitTitle(v.as_title, 8)}}</p>
                          </div>
                          <div class="bullet-cta">
                            <a data-toggle="collapse" @click="editSlider(v,k)" v-tooltip="'Edit Slider'" class="bullet_edit finger mr-2"><i
                                class="fas fa-pencil-alt"></i></a>
                            <a data-toggle="collapse" @click="doChangeStatus(k, v)" v-tooltip="'Change Status'" class="bullet_edit finger mr-2"><i
                                class="fas fa-cog"></i></a>
                            <a href="javascript:;" @click="doDelete2(v)" class="bullet_delete finger" v-tooltip="'Delete Slider'"><i
                                class="far fa-trash-alt"></i></a>
                          </div>

                          <div class="bullet-cta" style="left: 15px; z-index: 10">
                            <span 
                              class="label"
                              :class="v.as_is_active === 'Y' ?'label-success' : 'label-danger'"
                            >{{ v.as_is_active === "Y" ? "Active" : "Inactive" }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 order-3">
                        <a class="wrap_upload" @click="addSlider()" data-toggle="collapse" 
                          role="button" aria-expanded="false" aria-controls="collapseExample">
                          <div class="ic_wrap">
                            <i class="fas fa-plus"></i>
                            <p>New Slider</p>
                          </div>
                        </a>
                      </div>
                    </draggable>
                  </div>
                  <div class="mt-4" v-if="menuEdit">
                    <ValidationObserver v-slot="{ handleSubmit }" ref="VForm1">
                      <form @submit.prevent="handleSubmit(submitSlider)" class="row">
                        <div class="col-md-8 mb-3">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="panel_group">
                                <div class="panel_heading">Heading</div>
                                <div class="panel_body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="control-label">Title<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Title" @input="updateAlt($event, rowSlider, 'as_alt')"
                                          v-model="rowSlider.as_title">
                                        <VValidate name="Title Slider" v-model="rowSlider.as_title"
                                          :rules="{required: 1, min: 3, max: 150}" />
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="control-label">Description <span class="text-danger">*</span></label>
                                        <textarea class="form-control" rows="5" placeholder="Description"
                                          v-model="rowSlider.as_short_desc"></textarea>
                                        <VValidate name="Description Slider" v-model="rowSlider.as_short_desc"
                                          :rules="{required: 1, min: 3, max: 255}" />
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="control-label">Have Button? <span class="text-danger">*</span></label>
                                          <div class="d-flex">
                                            <div class="custom-control custom-radio" style="margin-right: 10px;">
                                              <input type="radio" name="radioRow1" id="Yes1" v-model="rowSlider.as_button" :value="'Y'" class="custom-control-input" @input="isHaveButton" />
                                              <label for="Yes1" class="custom-control-label">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                              <input type="radio" name="radioRow1" id="No1" v-model="rowSlider.as_button" :value="'N'" class="custom-control-input" @input="isHaveButton" />
                                              <label for="No1" class="custom-control-label">No</label>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="col-md-12" v-if="buttonCoba==='Y'">
                                      <div class="form-group">
                                        <label class="control-label">Button Label <span class="text-danger">*</span></label>
                                         <input 
                                          type="text" 
                                          class="form-control" placeholder="Button Label"
                                          v-model="rowSlider.as_button_label"
                                        >
                                        <VValidate name="button label" v-model="rowSlider.as_button_label"
                                          :rules="{required: 1, min: 3, max: 255}" />
                                      </div>
                                    </div>
                                    <div class="col-md-12" v-if="buttonCoba==='Y'">
                                      <div class="form-group">
                                        <label class="control-label">Button Link <span class="text-danger">*</span></label>
                                         <input 
                                          type="text" 
                                          class="form-control" placeholder="e.g illuminateasia.com/about"
                                          v-model="rowSlider.as_button_link"
                                        >
                                        <VValidate name="button link" v-model="rowSlider.as_button_link"
                                        :rules="{required: 1, min: 3, max: 255}" />
                                      
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group mt-3">
                            <Uploader v-model="rowSlider.as_image" type="slider" label="Slider Image" />
                            <VValidate name="Image Slider" v-model="rowSlider.as_image" :rules="{required : 1}" />
                          </div>

                          <div class="form-group mt-3">
                            <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Alt Image" v-model="rowSlider.as_alt">
                            <VValidate name="Alt Image Slider" v-model="rowSlider.as_alt" rules="required" />
                          </div>

                          <div class="form-group mt-3">
                            <label class="control-label">
                              Status
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div class="d-flex">
                              <div class="custom-control custom-radio" style="margin-right: 10px;">
                                <input type="radio" name="radioRow" id="Yes" v-model="rowSlider.as_is_active" value="Y" class="custom-control-input" />
                                <label for="Yes" class="custom-control-label">Active</label>
                              </div>
                              <div class="custom-control custom-radio">
                                <input type="radio" name="radioRow" id="No" v-model="rowSlider.as_is_active" value="N" class="custom-control-input" />
                                <label for="No" class="custom-control-label">Inactive</label>
                              </div>
                            </div>
                            <VValidate name="Status Slider" v-model="rowSlider.as_is_active"
                              :rules="mrValidation.as_is_active" />
                          </div>
                        </div>
                        <div class="col-12 mt-3 text-right">
                          <template v-if="type=='updateSlider'">
                            <button type="submit" class="btn btn-rounded btn-purple">
                            Update Slider</button>
                          </template>
                          <template v-else>
                            <button type="submit" class="btn btn-rounded btn-purple">
                            Add Slider</button>
                          </template>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title">Company Overview</h5>
                      <small>Last updated : {{(secOverview.sw_update_date||"").dates("format2")}}</small>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show in Home?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                          <input type="checkbox" :checked="secOverview.sw_description=='Y' ? true : false"
                            class="custom-control-input" id="secOverview" value="1">
                          <label class="custom-control-label" for="secOverview"
                            :checked="secOverview.sw_description=='Y' ? true : false">Yes</label>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-md-12 col-lg-6 col-sm-12">
                          <h4 style="font-weight: bold;">{{about.sw_title}}</h4>
                          <div class="about_wrap" v-html="about.sw_description">
                          </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-12 col-lg-5 col-sm-12">
                          <div class="row">
                            <div class="col-12 pad_left_7 aos-init aos-animate" data-aos="fade-up"
                              data-aos-duration="3000" data-aos-delay="350">
                              <div class="wrap_slider_img">
                                <img :src="uploader((about.sw_img || '').removeWebp())" class="img-responsive" />
                                <div class="slider_name">
                                  <p>{{about.sw_title}}</p>
                                </div>
                                <div class="bullet-cta">
                                  <a data-toggle="collapse" href="#collapseOverview" role="button" aria-expanded="false" v-tooltip="'Edit Content'"
                                    aria-controls="collapseOverview" class="bullet_edit"><i
                                      class="fas fa-pencil-alt"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 collapse mt-4" id="collapseOverview">
                          <ValidationObserver v-slot="{ handleSubmit }" ref="VFormOverview">
                            <form @submit.prevent="handleSubmit(submitOverview)" class="row">
                              <div class="col-md-8 mb-3">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="panel_group">
                                      <div class="panel_heading">Heading</div>
                                      <div class="panel_body">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <div class="form-group">
                                              <label class="control-label">Title<span
                                                  class="text-danger">*</span></label>
                                              <input type="text" class="form-control" placeholder="Heading"
                                                v-model="about.sw_title">
                                              <VValidate name="Title Slider" v-model="about.sw_title"
                                                :rules="{required: 1, min: 3, max: 50}" />
                                            </div>
                                          </div>
                                          <div class="col-md-12">
                                            <div class="form-group">
                                              <label class="control-label">Description</label>
                                              <CKEditor 
                                                :value.sync="about.sw_description" 
                                              />
                                              <VValidate name="Description" v-model="about.sw_description"
                                                :rules="{required: 1, min: 3, max: 750}" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 mb-3">
                                <Uploader v-model="about.sw_img" type="banner" label="Image" />
                                <VValidate name="Company Image" v-model="about.sw_img" rules="required" />
                                <div class="form-group mt-3">
                                  <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" placeholder="Alt Image"
                                    v-model="about.sw_alt">
                                  <VValidate name="Alt Image" v-model="about.sw_alt" rules="required" />
                                </div>
                              </div>
                              <div class="col-12 mt-3 text-right">
                                <button type="submit" class="btn btn-rounded btn-purple">Update Overview</button>
                              </div>
                            </form>
                          </ValidationObserver>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-9">
                          <h5 class="card-title mb-0">Our Services</h5>
                          <small>Last updated : {{(service.sw_update_date||"").dates("format2")}}</small>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <router-link :to="{name:'Services'}"
                              class="btn btn-rounded btn-purple">
                              Service List
                              <i class="fa fa-arrow-circle-right ml-2"></i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show this Section?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                          <input type="checkbox" :checked="service.sw_description=='Y' ? true : false"
                            class="custom-control-input" id="services" value="1"
                            v-on:change="filterStatus('serviceUpdate',service)">
                          <label class="custom-control-label" for="services"
                            :checked="service.sw_description=='Y' ? true : false">Yes</label>
                            <div class="checked_section" @click="filterStatus('serviceUpdate',service)" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-9">
                          <h5 class="card-title mb-0">Our Work</h5>
                          <small>Last updated : {{(caseStudy.sw_update_date||"").dates("format2")}}</small>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <router-link :to="{name:'OurWork'}"
                              class="btn btn-rounded btn-purple">
                              Our Work List
                              <i class="fa fa-arrow-circle-right ml-2"></i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show this Section?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                          <input type="checkbox" v-on:change="filterStatus('caseUpdate',caseStudy)"
                            :checked="caseStudy.sw_description=='Y' ? true : false" class="custom-control-input"
                            id="case" value="1">
                          <label class="custom-control-label" for="case"
                            :checked="caseStudy.sw_description=='Y' ? true : false">Yes</label>
                            <div class="checked_section" @click="filterStatus('caseUpdate',caseStudy)" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-9">
                          <h5 class="card-title mb-0">Article</h5>
                          <small>Last updated : {{(article.sw_update_date||"").dates("format2")}}</small>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <router-link :to="{name:'Article'}"
                              class="btn btn-rounded btn-purple">
                              Article List
                              <i class="fa fa-arrow-circle-right ml-2"></i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show this Section?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                          <input type="checkbox" v-on:change="filterStatus('updateArticle',article)"
                            :checked="article.sw_description=='Y' ? true : false" class="custom-control-input"
                            id="article" value="1">
                          <label class="custom-control-label" for="article"
                            :checked="article.sw_description=='Y' ? true : false">Yes</label>
                          <div class="checked_section" @click="filterStatus('updateArticle',article)" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-9">
                          <h5 class="card-title mb-0">News and Updates</h5>
                          <small>Last updated : {{(news.sw_update_date||"").dates("format2")}}</small>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <router-link :to="{name:'Article'}"
                              class="btn btn-rounded btn-purple">
                              Article List
                              <i class="fa fa-arrow-circle-right ml-2"></i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show this Section?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                          <input type="checkbox" v-on:change="filterStatus('updateNews',news)"
                            :checked="news.sw_description=='Y' ? true : false" class="custom-control-input"
                            id="news" value="1">
                          <label class="custom-control-label" for="news"
                            :checked="news.sw_description=='Y' ? true : false">Yes</label>
                          <div class="checked_section" @click="filterStatus('updateNews',news)" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title mb-0">Achievment</h5>
                      <small>Last updated : {{(achv.sw_update_date||"").dates("format2") || (achClients.sw_update_date||"").dates("format2")}}</small>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show this Section?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3 custom-control-inline">
                          <input type="checkbox" v-on:change="filterStatus('updateAchv',achv)"
                            :checked="achv.sw_description=='Y' ? true : false" class="custom-control-input" id="achv"
                            value="1">
                          <label class="custom-control-label" for="achv"
                            :checked="achv.sw_description=='Y' ? true : false">Yes</label>
                          <div class="checked_section" @click="filterStatus('updateAchv',achv)" ></div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-4">
                          <div class="ia-achievement">
                            <img class="ia-img"
                              :src="uploader((achClients.sw_img || '').removeWebp())" :alt="achClients.sw_title">
                            <h4 class="ia-achievement__count">{{achClients.sw_description}}+</h4>
                            <span
                              class="ia-achievement__title">{{achClients.sw_title}}</span>
                            <div class="bullet-cta">
                              <a data-toggle="collapse" @click="editAchievment(achClients)" href="#collapseAchievment" v-tooltip="'Edit Content'"
                                role="button" aria-expanded="false" aria-controls="collapseAchievment"
                                class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8 col-lg-4">
                          <div class="ia-achievement ia-achievement--green">
                            <img
                              :src="uploader((achProjects.sw_img || '').removeWebp())" :alt="achProjects.sw_title" class="ia-img">
                            <h4 class="ia-achievement__count">{{achProjects.sw_description}}+</h4>
                            <span
                              class="ia-achievement__title">{{achProjects.sw_title}}</span>
                            <div class="bullet-cta">
                              <a data-toggle="collapse" @click="editAchievment(achProjects)" href="#collapseAchievment" v-tooltip="'Edit Content'"
                                role="button" aria-expanded="false" aria-controls="collapseAchievment"
                                class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="collapse mt-4" id="collapseAchievment">
                        <ValidationObserver v-slot="{ handleSubmit }" ref="VFormAchevment">
                          <form @submit.prevent="handleSubmit(submitAchevment)" class="row">
                            <div class="col-md-8 mb-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="panel_group">
                                    <div class="panel_heading">Heading <br/>
                                    <small>Last updated : {{(row.sw_update_date||"").dates("format2")}}</small>
                                    </div>
                                    <div class="panel_body">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="form-group">
                                            <label class="control-label">Title<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="Title"
                                              v-model="row.sw_title">
                                            <VValidate name="Title" v-model="row.sw_title"
                                              rules="required" />
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="form-group">
                                            <label class="control-label">Total</label>
                                            <input type="number" min="0" class="form-control" placeholder="Total"
                                              v-model="row.sw_description">
                                            <VValidate name="Total" v-model="row.sw_description"
                                              rules="required" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 mb-3">
                              <Uploader v-model="row.sw_img" type="icon" label="Image"/>
                              <VValidate name="Image Achievement" v-model="row.sw_img"
                                rules="required" />
                              <div class="form-group mt-3">
                                <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Alt Image" v-model="row.sw_alt">
                                <VValidate name="Alt Image Achievement" v-model="row.sw_alt" rules="required" />
                              </div>
                            </div>
                            <div class="col-12 mt-3 text-right">
                              <button type="submit" class="btn btn-rounded btn-purple">Submit</button>
                            </div>
                          </form>
                        </ValidationObserver>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-9">
                          <h5 class="card-title mb-0">Our Client</h5>
                          <small>Last updated : {{(clients.sw_update_date||"").dates("format2")}}</small>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <router-link :to="{name:'Clients'}"
                              class="btn btn-rounded btn-purple">
                              Client List
                              <i class="fa fa-arrow-circle-right ml-2"></i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show this Section?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                          <input type="checkbox" v-on:change="filterStatus('updateClients',clients)"
                            :checked="clients.sw_description=='Y' ? true : false" class="custom-control-input"
                            id="clients" value="1">
                          <label class="custom-control-label" for="clients"
                            :checked="clients.sw_description=='Y' ? true : false">Yes</label>
                          <div class="checked_section" @click="filterStatus('updateClients',clients)" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-9">
                          <h5 class="card-title mb-0">Client Testimonial</h5>
                          <small>Last updated : {{(testimoni.sw_update_date||"").dates("format2")}}</small>    
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <router-link :to="{name:'Testimonial'}"
                              class="btn btn-rounded btn-purple">
                              Testimonial List
                              <i class="fa fa-arrow-circle-right ml-2"></i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-auto">
                          Show this Section?
                        </div>
                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                          <input type="checkbox" v-on:change="filterStatus('updateTestimoni',testimoni)"
                            :checked="testimoni.sw_description=='Y' ? true : false" class="custom-control-input"
                            id="testimoni" value="1">
                          <label class="custom-control-label" for="testimoni"
                            :checked="testimoni.sw_description=='Y' ? true : false">Yes</label>
                          <div class="checked_section" @click="filterStatus('updateTestimoni',testimoni)" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade show active" id="seo" role="tabpanel" v-if="tab === 'SEO'">
              <div class="card" v-if="isMeta">
                <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                  <form @submit.prevent="handleSubmit(doSubmitMeta)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Title <a
                                v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <input required="" type="text" v-model="seoStatic.sss_title" class="form-control" @input="checkDisabled"
                              placeholder="Meta Title">
                            <VValidate name="Meta Title" v-model="seoStatic.sss_title" :rules="{required: 1}" />
                          </div>

                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="control-label">
                              Meta Keyword
                              <a class="help_info"
                                v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                ?
                              </a>
                              <span class="text-danger mr5">*</span>
                            </label>
                            <input-tag v-model="seoStatic.sss_keyword" @input="checkDisabled"></input-tag>
                            <VValidate name="Keyword" v-model="seoStatic.sss_keyword" rules="required" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Description <a
                                v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <textarea required v-model="seoStatic.sss_desc" class="form-control" rows="4" @input="checkDisabled"></textarea>

                            <VValidate name="Meta Description" v-model="seoStatic.sss_desc" :rules="{required: 1}" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="form-actions text-right">
                        <button type="button" @click="reset" class="btn  btn-rounded btn-secondary" id="button-cancel" disabled>Cancel</button>
                        <button type="submit" class="btn  btn-rounded btn-info">Submit</button>
                      </div>
                    </div>

                  </form>
                </ValidationObserver>
              </div>
              <div class="card" v-else>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 text-right">
                      <label class="text-primary">Last updated : {{(seoStatic.sss_update_date||"").dates('format2')}}</label>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Title <a
                            v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <p>{{seoStatic.sss_title}}</p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="" class="control-label">
                          Meta Keyword
                          <a class="help_info"
                            v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                            ?
                          </a>
                          <span class="text-danger mr5">*</span>
                        </label>
                        <div></div>
                        <template v-for="(v,k) in seoStatic.sss_keyword" >
                          <label class="label label-success mr-1" :key="k">
                            {{v}}
                          </label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Description <a
                            v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <p>{{seoStatic.sss_desc}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="form-actions text-right">
                    <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .ia-achievement{
    padding: 32px;
    background-color: #4D2668;
    text-align: center;
    position: relative;
    &__count{
      margin-top: 24px;
      margin-bottom: 0;
      color: white;
      font-size: 48px;
      line-height: 48px;
      font-weight: 600;
    }
    &__title{
      color: white;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }
    &--green{
      background-color: #96BE50;
    }
  }
</style>

<script>
  import GlobalVue from '@libs/Global'
  import PageTitle from '@view/include/PageTitle'
  import Gen from '@libs/Gen'
  import draggable from 'vuedraggable'
  import CKEditor from '@/components/CKEditor'
  // import VModalVue from "../components/VModal.vue"

  let $ = global.jQuery
  let _ = global._
  export default {
    extends: GlobalVue,
    components: {
      PageTitle,
      draggable,
      CKEditor
    },
    data() {
      return {
        idKey: 'as_id',
        statusKey: 'as_is_active',
        sliderForm: 'add',
        rowSlider:{},
        type:"",
        tab: "",
        about:{},
        caseStudy:{},
        service:{},
        clients:{},
        testimoni:{},
        achv:{},
        article:{},
        news:{},
        achClients:{},
        achProjects:{},
        secOverview:{},
        row:{},
        seoStatic:{},
        enableAutoplay: false,
        isMeta: false,
        autoPlay: {},
        buttonCoba: 'N',
        TabSeo : JSON.parse(localStorage.getItem("SEO")),
        menuEdit: false,
        sliderFormArr: []
      }
    },
    mounted() {
      this.apiGet()
      this.apiGetAbout()
      this.apiAddition()
      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y"){
        this.tab = 'SEO'
        $("#main").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav").removeClass("active");
        $("#seo-nav").addClass("active");
      }
    },
    methods: {
      changeNum(e){
        let regex = /^[0-9]*$/
        if (!regex.test(e.target.value) && e.target.value.length < 13) {
          e.target.value = e.target.value.slice(0, -1)
        }
      },
      editMeta(){
        this.isMeta = true
      },
      apiGetAbout() {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest("/get/CompanyOverview")
          .then(res => {
            this.loadingOverlay = false
            this.about = res.data.about
            this.service = res.data.service
            this.caseStudy = res.data.case
            this.clients = res.data.clients
            this.testimoni = res.data.testimoni
            this.achClients = res.data.achClients
            this.achProjects = res.data.achProjects
            this.secOverview = res.data.secOverview
            this.achv = res.data.achv
            this.seoStatic = res.data.seoStatic
            this.article = res.data.article
            this.news = res.data.news
            this.autoPlay = res.data.autoPlay

          })
          .catch(() => this.loadingOverlay = false)
      },
      apiAddition() {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest("/get/Addition")
          .then(res => {
            this.loadingOverlay = false

            this.visibility = res.data.visibilityHome

            this.achievements = res.data.achievements

            setTimeout(() => {
              this.row = Object.assign({}, this.row, res.data.achievements)
            }, 300)

          })
          .catch(() => this.loadingOverlay = false)
      },
      addSlider() {
        this.whereMenu('add')
        this.type = "addSlider"
        this.rowSlider = {}
        this.rowSlider.as_is_active = 'Y'
        this.rowSlider.as_button = 'N'

        setTimeout(() => {
          if(this.rowSlider.as_button !== this.buttonCoba) this.buttonCoba = this.rowSlider.as_button
        }, 200)
      },
      editSlider(v,k) {        
        this.whereMenu('edit'+k)
        this.type = "updateSlider"
        this.rowSlider = v
        
        setTimeout(() => {
          if(this.rowSlider.as_button !== this.buttonCoba) this.buttonCoba = this.rowSlider.as_button
        }, 200)
      },
      whereMenu(val){
        this.sliderFormArr.push(val)
        if(val == this.sliderFormArr[1]){
          this.menuEdit = !this.menuEdit     
          if(this.sliderFormArr.length > 2){
            this.sliderFormArr.shift()
          }
        }
        else if(val !== this.sliderFormArr[1]){
          this.menuEdit = true
          if(this.sliderFormArr.length > 2){
            this.sliderFormArr.shift()
          }
        }
        else{
          this.menuEdit = true
          if(this.sliderFormArr.length > 2){
            this.sliderFormArr.shift()
          }
        }
      },
      editAchievment(v) {
        this.row = v
      },
      startDrag(){
        $(".sortable-fallback").removeAttr("style")
        $(".sortable-fallback").css("display","none")
      },
      endDrag() {
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              type: 'sort',
              data: this.data.data
            }
          },
          "POST"
        )
      },
      submitAchevment(type = 'VFormAchevment') {
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'submitAchevment'
          }, this.row),
          (type, resp) => {
            if (type == 'success') {
              $('#collapseAchievment').removeClass('show')
              this.loadingOverlay = true
              this.apiGet()
              this.apiGetAbout()
              this.apiAddition()
              this.row = {}
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          "POST", type
        )
      },
      submitSlider(IdFormSlider = 'VForm1') {
        var isAddSlider = this.type == "addSlider" ? true : false
        this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: this.type
          }, this.rowSlider),
          (type, resp) => {
            if (type == 'success') {
              this.menuEdit = false
              this.loadingOverlay = true
              this.apiGet()
              this.apiGetAbout()
              this.apiAddition()
              this.rowSlider = {}
              window.scrollTo(0, 0)
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormSlider, isAddSlider
        )
      },
      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#main").addClass("active").addClass("show");

        $("#seo-nav").removeClass("active");
        $("#main-nav").addClass("active");
        
        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
        
      },
      doSubmitMeta() {
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateMeta',
            id: this.seoStatic.sss_id
          }, this.seoStatic),
          (type, resp) => {
            if (type == 'success') {
              this.$router.push({
                name: this.$route.name
              })
              this.isMeta = false
              this.seoStatic = resp.data
              this.doBackSuccessMeta()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      },
      submitOverview(type= 'VFormOverview') {
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateCompanyOverview'
          }, this.about),
          (type, resp) => {
            if (type == 'success') {
              $('#collapseOverview').removeClass('show')
              this.loadingOverlay = true
              this.apiGet()
              this.apiGetAbout()
              this.apiAddition()
              this.rowSlider = {}
              window.scrollTo(0, 0)
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          "POST", type
        )
      },
      doDeleteUser(k, v) {
        this.doDelete2(v.as_id)
      },
      changeStatus(e, section) {
        let status = e.target.checked ? "active" : "inactive"
        Gen.apiRest(
          '/do/Addition', {
            data: {
              type: "status",
              status: status,
              title: "Home",
              section: section,
              update_by: this.user.id
            }
          },
          "POST"
        )
      },
      doChangeStatus(k,v){
        let id = v[this.idKey]
        let changeStatus = v[this.statusKey] == "Y" ? "N" : "Y"
        global.Swal.fire({
          title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false, 
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay=true

            Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'status',id:id,status:changeStatus}}, 
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                v[this.statusKey] = changeStatus

                global.Swal.fire({
                  title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                  icon: 'success',
                })

                this.menuEdit = false;
              })
              .catch((err)=>{ 
                this.loadingOverlay = false 
                if(err){
                    err.statusType = err.status;
                    err.status = "error";
                    err.message = err.response.data.message;
                    err.messageError = err.message
                }
                this.doSetAlertForm(err);
              })
          }
        })
      },
      doDelete2(v) {
        global.Swal.fire({
          title: 'Are you sure to delete?',
          text: "You won't be able to restore this data!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay = true
            this.openForm = false;
            Gen.apiRest("/do/" + this.modulePage, {
                data: {
                  type: 'delete',
                  id: v.as_id
                }
              }, "POST")
              .then(() => {
                this.apiGet()
                global.Swal.fire({
                  title: 'Data Deleted Successfully!',
                  icon: 'success',
                })

                this.menuEdit = false;
              })
              .catch((err) => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
                }
                this.doSetAlertForm(err);
              })
          }
        })
      },
      filterStatus(type, v) {
        var status_label = v.sw_description == 'Y' ?'hide' : 'show';
        global.Swal.fire({
        title: 'Are You sure to '+status_label+' this section?',
        icon: 'warning',
        // text: 'This category already has articles. The status of articles linked to this category will also be changed.',
        showCancelButton: true,
        confirmButtonText: `Yes, change it!`,
        customClass: {
          confirmButton: 'btn btn-custom',
          cancelButton: 'btn btn-outline-custom ml-1',
        },
        buttonsStyling: false, 
      }).then((result) => {
        if (result.value) {
          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: {
                type: type,
                data: v
              }
            },
            "POST"
          ).then(resp => {
            Gen.apiRest(
              "/get/"+this.modulePage+
              (this.pageSlug?"/"+this.pageSlug:"")+
              (this.pageId?"/"+this.pageId:""), 
              
            ).then(res=>{
              this.loadingOverlay = false
              _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
              })
              
              if(!this.isList){
                this.setFocusForm()
              }
              
            }).catch(()=>{
              this.loadingOverlay = false
            })
            Gen.apiRest("/get/CompanyOverview")
            .then(res => {
              this.loadingOverlay = false
              this.about = res.data.about
              this.service = res.data.service
              this.caseStudy = res.data.case
              this.clients = res.data.clients
              this.testimoni = res.data.testimoni
              this.achClients = res.data.achClients
              this.achProjects = res.data.achProjects
              this.secOverview = res.data.secOverview
              this.achv = res.data.achv
              this.seoStatic = res.data.seoStatic
              this.article = res.data.article
              this.news = res.data.news

            })
            .catch(() => this.loadingOverlay = false)
            return global.Swal.fire('Success', resp.data.message, 'success')
          })
        } else {
          this.apiGetAbout()
        }
      })
        
      },
      changeTab(tab){
        this.tab = tab
        if(this.tab === "SEO"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "Y"})
          localStorage.setItem("SEO", confSeo)
        }else{
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N"})
          localStorage.setItem("SEO", confSeo)
        }
      },
      reset(){
        this.apiGet()
        this.apiGetAbout()
        this.apiAddition()
        $('#button-cancel').attr('disabled', 'disabled')
      },
      checkDisabled(){
        $('#button-cancel').removeAttr('disabled')
      },
      splitTitle(content, len) {
        const countTitle = content.split(' ')
        const ctLen = countTitle.length
        if (ctLen > len) countTitle.splice(len)
        return `${countTitle.join(' ')} ${content.split(' ').length > len ? '...' : ''}`
      },
      updateAutoplay(type, e) {
        if(type === 'status'){
          Gen.apiRest(
            "/do/Home",
            {
              data: {
                type: "updateAutoPlay",
                status: e.target.checked ? "Y" : "N"
              }
            },
            "POST"
          )
          .then(res => this.autoPlay = res.data.data)
        } else if(type === 'delay'){
          Gen.apiRest(
            "/do/Home",
            {
              data: {
                type: "updateAutoPlay",
                delay: +this.autoPlay.sw_description
              }
            },
            "POST"
          )
            .then(res => this.autoPlay = res.data.data)
        }
      },
      isHaveButton(e){
        setTimeout(() => {          
          this.rowSlider.as_button = e.target.value
          this.buttonCoba = e.target.value
        }, 0)
      }
    },
    watch: {
      

      $route() {
        this.apiGet()
        this.apiGetAbout()
        this.apiAddition()      
      },
    },
  }
</script>